import {getFilteredQuestions, getFilteredTopics} from '@hconnect/common/utils'

import {Question, Section, Topic} from '../../types'

export const getSectionTopic = (section: Section, topicId: string): Topic | undefined =>
  section.topics.find(({id}) => topicId === id)

export const getSectionById = (sections: Section[], sectionId: string): Section | undefined =>
  sections.find(({id}) => id === sectionId)

export const getSectionIds = (sections: Section[]): string[] => sections.map(({id}) => id)

export const getTopicIds = (sections: Section[], selectedSectionIds?: string[]): string[] =>
  getFilteredTopics<Topic, Section>(sections, selectedSectionIds).map(({id}) => id)

export const getQuestionIds = (
  sections: Section[],
  sectionIds?: string[],
  topicIds?: string[]
): string[] =>
  getFilteredQuestions<Question, Topic, Section>(sections, sectionIds, topicIds).map(({id}) => id)
