/**
 * This file contains types that are part of the backend API.
 * Changes must be made in accordance with the API.
 */
import {AnswerStatus, QuestionDifficulty, ActionSource} from '@hconnect/common/types'

export const LocationDtoType = {
  Area: 'area',
  Region: 'region',
  Country: 'country'
} as const

export type LocationDtoType = (typeof LocationDtoType)[keyof typeof LocationDtoType]

export interface AnswerSubmission {
  answer?: AnswerStatus
  lastSubmitted: string
}

export interface Attachment {
  id: number
  fileName: string
  url: string // this is an encrypted url that changes constantly, it can also be used as a document ID
  mediaType: string
  previewUrl?: string
}

export interface Question {
  id: string
  subQuestions: string[]
  difficulty: QuestionDifficulty
  submission?: AnswerSubmission
  attachments: Attachment[]
  guidanceLink?: string
}

export const ActionStatus = {
  Pending: 'pending',
  Ongoing: 'ongoing',
  Done: 'done'
} as const

export type ActionStatusType = (typeof ActionStatus)[keyof typeof ActionStatus]

export const ACTION_STATUS_LIST: ActionStatusType[] = Object.values(ActionStatus)

export interface ActionItemMutation {
  title: string
  description: string
  source: ActionSource
  status: ActionStatusType
  questionId?: string
  assigneeId?: string
  dueDate: string
}

export type CommentStatus = 'new' | 'edited' | 'deleted'
