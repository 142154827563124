import {Comments, CommonComment} from '@hconnect/common/comments'
import {AnswerStatus} from '@hconnect/common/types'
import CloseIcon from '@mui/icons-material/Close'
import {Typography, Box, Theme, Stack, IconButton, Link} from '@mui/material'
import {toLower} from 'lodash'
import moment from 'moment-timezone'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {ANSWER_STATES, colorByAnswer} from '../../common/domain/questionnaire'
import {useAddComment} from '../../hooks/api/useAddComment'
import {useComments} from '../../hooks/api/useComments'
import {useDeleteComment} from '../../hooks/api/useDeleteComment'
import {useEditComment} from '../../hooks/api/useEditComment'
import {useTimeZone} from '../../hooks/useTimeZone'
import {PomComment, QuestionItem} from '../../types'

import {Actions} from './Actions'
import {AnswerButton} from './AnswerButton'
import {AnswerIcon} from './AnswerIcon'
import {Attachments} from './Attachments'
import {QuestionsText} from './QuestionsText'

interface QuestionDetailProps {
  selectedQuestion: QuestionItem
  onClose: () => void
  onSelected: (id: string, answer: AnswerStatus) => void
}

export const mapComments = (comments?: PomComment[]): CommonComment[] | undefined =>
  comments?.map((comment) => ({
    text: comment.value,
    id: comment.id,
    createdBy: comment.createdBy,
    updatedBy: comment.updatedBy,
    createdOn: moment(comment.createdOn),
    updatedOn: comment.updatedOn ? moment(comment.updatedOn) : undefined
  }))

export const QuestionDetail: React.FC<QuestionDetailProps> = (props) => {
  const {selectedQuestion, onClose, onSelected} = props
  const {answer: selectedAnswer, id, questions, guidanceLink} = selectedQuestion
  const {t} = useTranslation()
  const timeZone = useTimeZone()

  const {data: comments} = useComments(id)
  const {mutate, isLoading} = useAddComment()

  const data: CommonComment[] | undefined = useMemo(() => mapComments(comments), [comments])

  const {mutate: deleteComment, isLoading: deleting} = useDeleteComment()
  const {mutate: saveEditedComment, isLoading: editSaving} = useEditComment()

  const onCommentDelete = useCallback(
    (id: string) => {
      const commentToDelete = comments?.find((comment) => comment.id === id)
      if (commentToDelete) {
        deleteComment(commentToDelete)
      }
    },
    [comments, deleteComment]
  )

  const onEditCommentSave = useCallback(
    (id: string, value: string) => {
      const commentToEdit = comments?.find((comment) => comment.id === id)
      if (commentToEdit) {
        saveEditedComment({...commentToEdit, value})
      }
    },
    [comments, saveEditedComment]
  )

  return (
    <Box mx={1.5} data-test-id="question-detail">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <Typography
          variant="caption"
          sx={(theme: Theme) => ({
            color: theme.palette.grey[500]
          })}
        >
          {id}
        </Typography>
        <IconButton onClick={onClose} data-test-id="question-detail-close">
          <CloseIcon />
        </IconButton>
      </Box>
      <Typography variant="h3">
        <QuestionsText questions={questions} />
      </Typography>
      {guidanceLink && (
        <Link
          href={guidanceLink}
          target="_blank"
          rel="noopener noreferrer"
          variant="caption"
          data-test-id="question-detail-link"
        >
          {t('questionnaire.moreInformation')}
        </Link>
      )}
      <Stack mt={3} spacing={1.5} direction="row">
        {ANSWER_STATES.map((answer: AnswerStatus) => (
          <AnswerButton
            key={answer}
            color={colorByAnswer(answer)}
            startIcon={<AnswerIcon answer={answer} />}
            text={t(`answers.${answer}`)}
            isSelected={answer === selectedAnswer}
            onClick={() => onSelected(id, answer)}
            data-test-id={`question-detail-btn-${toLower(answer)}`}
          />
        ))}
      </Stack>
      <Box>
        <Actions question={selectedQuestion} />
        <Comments
          data={data}
          isLoading={isLoading}
          deleteCommentProps={{
            onDelete: onCommentDelete,
            deletingInProgress: deleting
          }}
          onAddComment={(value) => mutate({questionId: id, comment: value})}
          editCommentProps={{
            savingChangesInProgress: editSaving,
            onEdit: onEditCommentSave
          }}
          timeZone={timeZone}
        />
        <Attachments questionId={id} attachedFiles={selectedQuestion.attachments} />
      </Box>
    </Box>
  )
}
