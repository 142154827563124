import {ActionSource, ACTION_SOURCES} from '@hconnect/common/types'
import {Autocomplete, CircularProgress, TextField} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

type Props = {
  onChange?: (data: ActionSource | undefined) => void
  error?: boolean
  value?: ActionSource
}

export const SourceField: React.FC<Props> = (props) => {
  const {t} = useTranslation()
  const {onChange, error, value} = props
  const [open, setOpen] = React.useState(false)
  const [searchTerm, setSearchTerm] = React.useState<string>('')

  const loading = false //  comes from react query api

  return (
    <Autocomplete
      data-test-id="action-source"
      open={open && ACTION_SOURCES.length >= 0}
      onChange={(event, next, reason) => {
        if (reason === 'clear') {
          onChange && onChange(undefined)
        } else {
          onChange && next && onChange(next)
        }
      }}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      value={value}
      options={ACTION_SOURCES}
      inputValue={searchTerm}
      onInputChange={(event, newValue) => {
        if (searchTerm !== newValue) {
          setSearchTerm(newValue)
        }
      }}
      getOptionLabel={(option) => {
        return t(`actionSource.${option}`)
      }}
      // isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          label={t('actionDialog.source')}
          error={error}
          required
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
    />
  )
}
