import {UserDto, ACTION_SOURCES} from '@hconnect/common/types'
import {QUESTION_CATEGORIES_LIST} from '@hconnect/common/utils'
import {FilterOption, FilterButton, DateRangePicker} from '@hconnect/uikit/src/lib2'
import {defaultQuickSelectionItems} from '@hconnect/uikit/src/lib2/components/simpleDatePicker/dateRangePickerHelpers'
import {Box} from '@mui/material'
import {isEmpty} from 'lodash'
import React, {useState, useMemo, useCallback, ReactNode} from 'react'
import {useTranslation} from 'react-i18next'

import {getSectionIds, getTopicIds, getSectionById, getQuestionIds} from '../common/domain/section'
import {FilterMultiSelect} from '../components/filters/FilterMultiSelect'
import {selectFieldOnBlueSx} from '../components/filters/filterStyles'
import {FilterTextField} from '../components/filters/FilterTextField'
import {ResponsiblePersonField} from '../components/responsiblePerson/ResponsiblePersonField'
import {useSections} from '../hooks/api/useSections'
import {useUserById} from '../hooks/api/useUser'
import {
  useTitleFilter,
  useSectionFilter,
  useTopicFilter,
  useAssigneeFilter,
  useQuestionIdFilter,
  useSourceFilter,
  useStatusFilter,
  useDueDateRangeParam,
  useDueDateRange,
  useQuestionCategoriesFilter
} from '../hooks/urlParameters/useActionFilters'
import {useTimeZone} from '../hooks/useTimeZone'
import {ActionFilterParameters} from '../types'
import {ACTION_STATUS_LIST} from '../types/backend.types'

type OptionKey = keyof ActionFilterParameters

const calcVisibleOptions = (filterParams: ActionFilterParameters): OptionKey[] =>
  Object.keys(filterParams)
    .map((key) => (isEmpty(filterParams[key]) ? undefined : key))
    .filter(Boolean) as OptionKey[]

const isChecked = (key: OptionKey, options: OptionKey[]): boolean => options.includes(key)

// keys filtered on frontend without backend call
export const CLIENT_SIDE_FILTER_KEYS: OptionKey[] = ['questionCategories']

const optionKeys: OptionKey[] = [
  'title',
  'sources',
  'sectionIds',
  'topicIds',
  'questionIds',
  'assignees',
  'dueDateRange',
  'statuses',
  ...CLIENT_SIDE_FILTER_KEYS
]

type FilterBarProps = {
  additionalActions?: ReactNode
}

export const FilterBar: React.FC<FilterBarProps> = ({additionalActions}) => {
  const {t} = useTranslation()
  const timezone = useTimeZone()
  const [title, setTitle] = useTitleFilter()
  const [sectionIds, setSectionIds] = useSectionFilter()
  const [topicIds, setTopicIds] = useTopicFilter()
  const [assignee, setAssignee] = useAssigneeFilter()
  const [timeRange, setTimeRange] = useDueDateRangeParam()
  const dueDateRange = useDueDateRange(timezone)
  const {data: user} = useUserById(assignee)

  const [questionIds, setQuestionIds] = useQuestionIdFilter()
  const [sources, setSources] = useSourceFilter()
  const [statuses, setStatuses] = useStatusFilter()
  const [questionCategories, setQuestionCategories] = useQuestionCategoriesFilter()
  const {data: sectionData = []} = useSections()
  const sectionDataIds = useMemo(() => getSectionIds(sectionData), [sectionData])
  const topicDataIds = useMemo(
    () => getTopicIds(sectionData, sectionIds),
    [sectionData, sectionIds]
  )
  const questionDataIds = useMemo(
    () => getQuestionIds(sectionData, sectionIds, topicIds),
    [sectionData, sectionIds, topicIds]
  )

  const resetMap: Record<OptionKey, () => void> = useMemo(
    () => ({
      title: () => setTitle(undefined),
      sectionIds: () => setSectionIds(undefined),
      topicIds: () => setTopicIds(undefined),
      assignees: () => setAssignee(undefined),
      questionIds: () => setQuestionIds(undefined),
      sources: () => setSources(undefined),
      statuses: () => setStatuses(undefined),
      questionCategories: () => setQuestionCategories(undefined),
      dueDateRange: () => {
        setTimeRange(undefined)
      }
    }),
    [
      setAssignee,
      setQuestionCategories,
      setQuestionIds,
      setSectionIds,
      setSources,
      setStatuses,
      setTimeRange,
      setTitle,
      setTopicIds
    ]
  )

  const setTopicIdsAndResetChildren = useCallback(
    (v: string[] | undefined) => {
      setTopicIds(v)
      setQuestionIds(undefined)
    },
    [setTopicIds, setQuestionIds]
  )

  const setSectionIdsAndResetChildren = useCallback(
    (v: string[] | undefined) => {
      setSectionIds(v)
      setTopicIdsAndResetChildren(undefined)
    },
    [setSectionIds, setTopicIdsAndResetChildren]
  )

  const [visibleOptions, setVisibleOptions] = useState<OptionKey[]>(
    calcVisibleOptions({
      title,
      statuses,
      questionCategories,
      sources,
      sectionIds,
      assignees: assignee ? [assignee] : [],
      topicIds,
      questionIds,
      dueDateRange
    })
  )

  const options: FilterOption<OptionKey>[] = optionKeys.map((optionId) => {
    return {
      id: optionId,
      label: t(`filter.${optionId}`)
    }
  })

  return (
    <Box display={'flex'} justifyContent={'flex-end'} gap={1.5} flexWrap={'wrap'}>
      {isChecked('title', visibleOptions) && (
        <FilterTextField
          value={title}
          onChange={setTitle}
          data-test-id={'filter-control-title'}
          label={t('filter.title')}
        />
      )}
      {isChecked('sources', visibleOptions) && (
        <FilterMultiSelect
          value={sources}
          onChange={setSources}
          options={ACTION_SOURCES}
          id={'filter-source'}
          label={t('filter.sources')}
          entryToString={(source) => t(`actionSource.${source}`)}
          data-test-id={'filter-control-source'}
        />
      )}
      {isChecked('sectionIds', visibleOptions) && (
        <FilterMultiSelect
          value={sectionIds}
          onChange={setSectionIdsAndResetChildren}
          options={sectionDataIds}
          id={'filter-section'}
          label={t('filter.sectionIds')}
          entryToString={(id) => getSectionById(sectionData, id)?.name ?? ''}
          data-test-id={'filter-control-section'}
        />
      )}
      {isChecked('topicIds', visibleOptions) && (
        <FilterMultiSelect
          value={topicIds}
          onChange={setTopicIdsAndResetChildren}
          options={topicDataIds}
          id={'filter-topic'}
          label={t('filter.topicIds')}
          data-test-id={'filter-control-topic'}
        />
      )}
      {isChecked('questionIds', visibleOptions) && (
        <FilterMultiSelect
          value={questionIds}
          onChange={setQuestionIds}
          options={questionDataIds}
          id={'filter-question-id'}
          label={t('filter.questionIds')}
          data-test-id={'filter-control-questionId'}
        />
      )}
      {isChecked('assignees', visibleOptions) && (
        <ResponsiblePersonField
          sx={selectFieldOnBlueSx}
          value={assignee ? user : undefined}
          onChange={(user?: UserDto) => {
            setAssignee(user?.userId)
          }}
        />
      )}
      {isChecked('dueDateRange', visibleOptions) && (
        <DateRangePicker
          onTimeRangeParamChange={setTimeRange}
          timeRangeParam={timeRange}
          singleDateSwitchLabel={t('quickSelection.pickSingleDate')}
          selectionItems={defaultQuickSelectionItems(t, timezone, new Date())}
          timezone={timezone}
        />
      )}
      {isChecked('statuses', visibleOptions) && (
        <FilterMultiSelect
          value={statuses}
          onChange={setStatuses}
          options={ACTION_STATUS_LIST}
          id={'filter-status'}
          label={t('filter.statuses')}
          entryToString={(status) => t(`actions.${status}`)}
          data-test-id={'filter-control-status'}
        />
      )}
      {isChecked('questionCategories', visibleOptions) && (
        <FilterMultiSelect
          value={questionCategories}
          onChange={setQuestionCategories}
          options={QUESTION_CATEGORIES_LIST}
          id="filter-question-categories"
          label={t('filter.questionCategories')}
          entryToString={(category) => t(`actions.${category}`)}
          data-test-id={'filter-control-question-category'}
        />
      )}
      <FilterButton
        caption={t('filter.button.caption')}
        options={options}
        selectedIds={visibleOptions}
        onOptionChange={(optionId, checked) => {
          !checked && resetMap[optionId]?.()
          setVisibleOptions((options) =>
            checked ? [...options, optionId] : options.filter((id) => id !== optionId)
          )
        }}
      />
      {additionalActions}
    </Box>
  )
}
