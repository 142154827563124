import {QuestionDifficulty, ActionSource, ACTION_SOURCES} from '@hconnect/common/types'
import {QUESTION_CATEGORIES_LIST} from '@hconnect/common/utils'
import {TimeZone, DateRange} from '@hconnect/uikit'
import {
  defaultQuickSelectionItems,
  timeRangeParamToDateRange
} from '@hconnect/uikit/src/lib2/components/simpleDatePicker/dateRangePickerHelpers'
import {isEqual} from 'lodash'
import {useMemo, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {usePrevious} from 'react-use'

import {ActionFilterParameters} from '../../types'
import {ActionStatusType, ACTION_STATUS_LIST} from '../../types/backend.types'
import {useTimeZone} from '../useTimeZone'

import {
  useStringSearchParameter,
  SearchParameterKey,
  useSearchParameter,
  useStringSearchParam
} from './useSearchParameter'

const ARRAY_DELIMITER = ','

const stringToList = (str?: string): string[] => str?.split(ARRAY_DELIMITER) ?? []

const stringToTList = <T extends string>(options: T[], str?: string): T[] =>
  options.filter((opt) => stringToList(str).includes(opt))

const stringToStatusList = (statusStr?: string): ActionStatusType[] =>
  stringToTList(ACTION_STATUS_LIST, statusStr)

const stringToQuestionCategoryList = (statusStr?: string): QuestionDifficulty[] =>
  stringToTList(QUESTION_CATEGORIES_LIST, statusStr)

const stringToSourceList = (sourceStr?: string): ActionSource[] =>
  stringToTList([...ACTION_SOURCES], sourceStr)

const listToString = <T extends string>(list?: T[]): string | undefined =>
  list ? list.join(ARRAY_DELIMITER) : undefined

export const useTitleFilter = () => useStringSearchParameter('title')

export const useSectionFilter = () =>
  useSearchParameter<SearchParameterKey, string[]>('sectionIds', listToString, stringToList)

export const useTopicFilter = () =>
  useSearchParameter<SearchParameterKey, string[]>('topicIds', listToString, stringToList)

export const useAssigneeFilter = () => useStringSearchParameter('assignees')

export const useQuestionIdFilter = () =>
  useSearchParameter<SearchParameterKey, string[]>('questionIds', listToString, stringToList)

export const useSourceFilter = () =>
  useSearchParameter<SearchParameterKey, ActionSource[]>(
    'sources',
    listToString,
    stringToSourceList
  )

export const useStatusFilter = () =>
  useSearchParameter<SearchParameterKey, ActionStatusType[]>(
    'statuses',
    listToString,
    stringToStatusList
  )

export const useQuestionCategoriesFilter = () =>
  useSearchParameter<SearchParameterKey, QuestionDifficulty[]>(
    'questionCategories',
    listToString,
    stringToQuestionCategoryList
  )

export const useDueDateRangeParam = () =>
  useStringSearchParam<SearchParameterKey>('dueDateRange', undefined)

export const useDueDateRange = (
  timezone: TimeZone,
  now = new Date()
): DateRange<Date> | undefined => {
  const {t} = useTranslation()
  const [timeRange] = useDueDateRangeParam()

  const selectionItems = defaultQuickSelectionItems(t, timezone, now)
  const [from, to] = timeRangeParamToDateRange({timeRange, selectionItems})
  return from && to ? {from, to} : undefined
}

export const useActionFilters = (onFilterChange?: () => void): ActionFilterParameters => {
  const timezone = useTimeZone()
  const [title] = useTitleFilter()
  const [sources] = useSourceFilter()
  const [statuses] = useStatusFilter()
  const [questionCategories] = useQuestionCategoriesFilter()
  const [sectionIds] = useSectionFilter()
  const [topicIds] = useTopicFilter()
  const [questionIds] = useQuestionIdFilter()
  const [assignee] = useAssigneeFilter()
  const dueDateRange = useDueDateRange(timezone)

  const filters = useMemo(
    () => ({
      title,
      sources,
      statuses,
      questionCategories,
      sectionIds,
      topicIds,
      questionIds,
      assignees: assignee ? [assignee] : undefined,
      dueDateRange
    }),
    [
      assignee,
      dueDateRange,
      questionIds,
      sectionIds,
      sources,
      questionCategories,
      statuses,
      title,
      topicIds
    ]
  )
  const prevFilters = usePrevious(filters)

  useEffect(() => {
    if (prevFilters && !isEqual(prevFilters, filters)) {
      onFilterChange?.()
    }
  }, [filters, onFilterChange, prevFilters])

  return filters
}
